@import url('https://fonts.googleapis.com/css2?family=Open+Sans:wght@400;600;700&family=Roboto:wght@300&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Abril+Fatface&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Gafata&display=swap');

:root {
  --greyblue: #1a2c3a;
  --gentian: #004f7c;
  --mustard: #e29000;
  --white: #ecece7;
  --reseda: #6b7c59;
  --black: #222427;
  --orange: #923e25;
  --lightyellow: #c2a973;
}

.calendly-badge-widget {
  position: relative!important;
  padding-top: 1rem;
  padding-bottom: 2rem;
  bottom: 0!important;
  right: 0!important;
  margin: auto;
  z-index: 1!important;
}

/* width */
::-webkit-scrollbar {
  width: 4px;
}

/* Track */
::-webkit-scrollbar-track {
  border-radius: 1px;
  background-color: var(--white);
  border: .1px solid #cacaca;
  box-shadow: inset 0 0 1px rgba(0, 0, 0, .3);
}
 
/* Handle */
::-webkit-scrollbar-thumb {
  border-radius: 1px;
  background-color: var(--greyblue);
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: #6e6e6e; 
}

*{
font-family: 'Open Sans', sans-serif;
/* font-family: 'Roboto', sans-serif; 
font-family: 'Abril Fatface', cursive; 
font-family: 'Gafata', sans-serif;  */
}
#root{
  margin:0; 
  display:flex; 
  flex-direction:column;
}

h1 {
  font-size: 2rem!important;
}

h1, h3{
  text-align: center;
}

h3 {
  font-size: 1.5rem!important;
}

h2, h4{
  font-weight: 600 !important;
  font-size: 1.6rem !important;
  text-align: justify;
  margin-top: .5rem !important;
  justify-content: right;
  text-justify: right;
}

h5{
  text-align: center;
}

h6{
  text-align: center;
  padding-top: 2rem;
  padding-bottom: .5rem;
}

label{
  display: flex!important;
  width: auto;
  justify-content: center;
  max-width: 20rem;
}

textarea{
  min-height: 10rem!important;
}

html {
  height: 100%;
  overflow-x: hidden;
}
  
section{
    height: 100svh;
    width: 100svw;
    overflow: hidden;
    justify-content: center;
    display: flex;
}

.btn-secondary{
  --bs-btn-active-bg: #1a1a1a!important;
}

p {
  margin: auto !important;
  padding: 1rem;
  font-size: 1rem;
  color: black;
  text-decoration: none;
  flex-grow: 8;
  text-align: center;
}

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}
