/* HEADER */
header {
  flex-grow: 1;
  display: block;
  width: 100%;
  height: auto;
  z-index: 1000;
  /* background: linear-gradient(to top, transparent, rgb(39, 39, 39)); */
}

.react-player video::-webkit-media-controls {
  display: none !important;
  }

.react-player video::-webkit-media-controls-panel {
  display: none !important;
  }


.header-regular{
  align-items: flex-start!important;
  padding: 1rem;
  height: 100%;
}

.header-group{
  justify-content: center;
}

#offcanvasNavbar-expand-false{
  width: 100%;
  display: flex;
  flex-direction: row;
  background-color: var(--reseda);
  justify-content: space-evenly;
  padding-top: 1rem;
}

.nav-mobile{
  flex-direction: column!important;
  padding: 2rem;
  padding-top: none!important;
  height: 100%;
  justify-content: space-between!important;
}

nav{
  height: 100%;
  display: flex;
  padding: 0!important;
}

.container-fluid{
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.navbar-toggler{
  border: none!important;
}

.btn-close{
  margin: auto!important;
  --bs-btn-close-bg: url("/src/img/close.png")!important;
  font-size: 2rem;
}

a {
  padding-bottom: .5rem;
  font-weight: 100;
  color: black !important;
  text-decoration: none !important;
  text-align: left;
  pointer-events: all;
}

.header-title{
  padding-right: 2rem;
  border-bottom: 1px solid var(--white);
  color: var(--white)!important;
}
.header-title-mobile{
  padding-right: 2rem;
  border-bottom: 1px solid var(--white);
  color: var(--white)!important;
  font-size: 1.8rem;
}

.logo {
  /* width: 10rem; */
  height: 5rem;
  margin-right: 2rem;
  margin-left: 2rem;
}
.logo-mobile{
  height: 8rem;
  padding-bottom: 1rem;
}

.mobile-main-div{
  position: absolute;
  padding: 2rem;
  top: 0;
  right: 0;
}

.logo-long {
  /* width: 10rem; */
  height: 4rem;
  margin-right: 2rem;
  margin-left: 2rem;
}

/* HEADER END */

/* FOOTER */

footer {
  flex-grow: 1;
  display: flex;
  width: 100%;
  position: relative;
  /* background:  rgb(39, 39, 39); */
  bottom: 0;
  /* max-height: 180px; */
  padding-top: 1rem;
  padding-bottom: .5rem;
  padding-left: 0;
  padding-right: 0;
  justify-content: space-between;
}

.social-icon{
  height: 2rem!important;
  width: 2rem!important;
  margin: .5rem;
  padding: 1.1rem;
}

/* HOME SCREEN */

.fullpage-wrapper{
  visibility: visible;
  top: 0;
  position: absolute;
  height: auto;
  transition: all 1000ms ease 0s
}

#carousel-container{
  height: 100%;
}

.page02{
  background: var(--lightyellow);
  position: relative;
}

.page03{
  background: var(--reseda);
}

* > .page03{
  color: var(--white);
}

li {
  list-style: none;
  padding-top: 1rem;
}

.display-services{
  display: flex;
  flex-direction: column;
  align-items: stretch;
}

.services-list{
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
  align-items: stretch;
  padding: 0;
  margin-top: 0;
  margin-bottom: 0;
  height: 100%;
}

.model-canvas, .model-box{
  flex-grow: auto;
  height: 100%;
  display: flex;
}

.model-canvas * {
  touch-action: none;
  border-radius: 1rem;
}

.render-item{
  position: absolute;
}

#white{
  color: var(--white);
}
.p-service-home:hover{
  color: var(--lightyellow)!important;
  cursor: pointer;
}

.container-page03{
  display: flex;
  flex-direction: column;
  max-width: 75%;
  min-width: 20rem;
  min-height: 70%;
  margin: auto;
  padding: 2rem;
}

.services-box{
  height: 100%;
  display: flex;
  flex-direction: row;
  background: var(--reseda);
  border-radius: 1rem;
  max-width: 100%;
  flex: 1;
}


.container-intro{
  display: flex;
  flex-direction: column;
  width: 70vw;
  max-width: 40rem;
  min-width: 15rem;
  margin: auto;
  padding: 2rem;
  z-index: 1000;
  transform: translateZ(10px)
}

.container-intro * {
  color: black;
  font-weight: 800;
  /* -webkit-text-stroke: .2px #000000; */
}

.carousel-image, .page02 video{
  width: 100svw;
  height: 100svh;
  object-fit: cover;
  overflow: hidden;
  display: inline;
}

.video-background{
  background-color: rgba(255, 255, 255, 0.15);
  height: 100%;
  width: 100%;
  position: absolute;
  z-index: 200;
  transform: translateZ(1px)
}

.carousel-item {
  overflow: hidden;
  width: 100%;
  }

.carousel-caption{
  float: bottom;
  color: var(--black)!important;
  font-weight: 900!important;
}
.scroll{
  cursor: pointer;
  position: fixed;
  float: left;
  bottom: 0;
  margin: 2rem;
  height: 10rem;
  z-index: 10;
  transition: all .2s ease-in-out;
  overflow: visible;
}

.scroll:hover .stoke, .scroll:hover {
  stroke: var(--white)!important; 
  stroke-width: 2.5px!important;
  fill: var(--white)!important;
  height: 12rem;
}

/* HOME SCREEN END */

.model-questions{
  height: 16rem;
  width: 100%;
}

.list{
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
  width: 30%;
  top: 0;
  bottom: 0;
}

.para-services{
  padding: 1rem;
  font-size: 1rem;
  height: fit-content;
  }
.container{
  flex:1;
  max-width: 80% !important;
  padding-top: 1rem;
}

.container-question{
  position: relative;
  display: flex;
  height: 100%;
  max-width: 94rem!important;
  margin: auto!important;
  z-index: 1;
  background-color: white;
  transition: all 2s!;
}

.container-services-description{
  height: 100% !important;
  width: 100%;
  position: absolute !important;
  z-index: 5;
  display: none;
  opacity: 0%;
  transition: 1s;
}
.container-services-description:hover {
  display: block;
  opacity: 100%;
}

.container-landing{
  height: 100%;
  width: 100%;
  position: absolute;
  background-color: white;
  z-index: 10;
  top: 0;
  justify-content: center;
  /* transition: all .2s ease-in-out; */
}
.nav-link{
  margin: auto;
}
.landing-logo{
  /* height: 40%; */
  width: 45%;
  margin: auto;
  display: block;
  overflow: visible;
  z-index: 10;
}

.input-group {
  padding: 1rem;
}

.slogan{
  text-align: justify !important;
}

.box-question{
  margin: auto;
  padding: 2rem;
  display: flex;
  flex-direction: column;
  max-width: 100rem;
  height: 100%!important;
}
.answer-section-cards{
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  margin: auto;
  justify-content: center;
}
.answer-section{
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  margin: auto;
  justify-content: center;
}
#answer-section-first-form{
  flex-direction: row!important;
  margin-bottom: 2rem;
}

#answer-section-input{
  display: flex;
  flex-direction: column;
  max-width: 40rem;
  margin: auto;
}
#answer-section-submit{
  max-width: 50rem;
  margin: auto;
}
.questions-summary{
  padding-bottom: 1rem;
}

.form-check{
  padding: 1rem!important;
  max-width: 22rem;
  max-height: 18rem;
  flex: 1 0 100%; /* explanation below */
  margin: 1.3rem!important;

}

.form-check-choice{
  margin: auto;
  padding: 1rem;
}

.buttons {
  display: flex;
  margin: auto;
  padding: 1rem;
  justify-content: center;
  min-height: 100%;
}

.button-questions{
  display: flex;
  margin: 2rem;
}

.question-count{
  text-align: center;
}

.question-section{
  max-width: 40rem;
  min-width: 40rem;
  margin: auto;
}

.logo-image{
  width: 100%;
}

.card-project-individual{
  height: 100%!important;
}

.service-image{
  object-fit: cover;
  overflow: hidden;
  display: inline;
  height: 100%;
}

.intro-image {
  width: 100svw;
  height: 100svh;
  --x: 500px;
  --y: 300px;
  background-image: url('/src/img/home02.jpg');
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center; 
  position: relative;
  display: block;
}

.button-questionnaire{
 width: 10rem;
 margin: auto;
}
.button-questionnaire-link{
  margin: auto;
 }

.intro-image::after {
  content: '';
  width: 100%;
  height: 100%;
  position: absolute;
  background-size: cover;
  top: 0;
  left: 0;
  background-image: url('/src/img/home01.jpg');
  background-size: cover;
  background-position: center; 
  z-index: 1;
  -webkit-mask-repeat: no-repeat no-repeat;
  mask-repeat: no-repeat no-repeat;
  -webkit-mask-image: radial-gradient(300px at var(--x) var(--y), transparent 95%, black 100%);
  mask-image: radial-gradient(300px at var(--x) var(--y), transparent 95%, black 100%);
}

.intro-fade{
  height: 100%;
  width: 100%;
  background-color: rgba(255, 255, 255, 0.7);
  display: grid;
  justify-content: center;
}

.img-aboutus {
  width: 100%;
  margin: auto;
  justify-content: center;
  display: block;
}

#canvas-video{
  width: 100%;
  max-height: 40rem;
  object-fit: cover;
  overflow: hidden;
  display: inline;
}

.london{
margin: auto;
}
.cities{
  display: flex;
  flex-direction: row;
  justify-content: space-evenly;
  margin: auto;
  max-width: 100%;
  flex-wrap: wrap;
}
.london-div{
  min-height: 360px;
  height: 360px;
  max-width: 50%;
  display: flex;
  flex-direction: column;
  margin: auto;
}
.london-div *{
  margin: auto;
  margin-bottom: 1rem;
}

.aboutus-block{
  width: 100%;
  padding-bottom: 1rem;
  padding-top: 1rem;
  margin-bottom: .5rem;
  position: relative;
  display: flex;
  justify-content: space-between;
}

.our-expertise{
  margin: auto;
}
.our-expertise>*{
  text-align: left;
  padding-top: 0rem!important;
  padding-left: 0;
  text-align: justify;
}
.title-aboutus {
  text-align: left;
  position: relative;
  top: 0;
  margin-top: 0!important;
}

.para-aboutus{
  padding: 0rem;
  width: 100%;
  text-align: justify;
  padding: 0rem;
}

.contact-info{
  max-width: 50%;
  justify-content: center;
  margin: auto;
  display: flex;
  flex-direction: column;
}

.project-description{
  text-align: justify;
  width: 100%;
}

.footer-content {
  display: flex;
  flex-direction: column;
  position: relative;
  padding: .1rem;
}

.footer-content > * {
  margin: auto;
  padding: .3rem;
  text-align: center;
}
.material-select{
  position: relative;
  float: right!important;
  margin-right: 0!important;
  right: 0;
}
.light-text{
  font-weight: 100;
  font-size: small;
}
.para-contact {
  width: 100%;
  text-align: center;
  padding: .5rem;
}

.para-privacy{
  width: 100%;
  text-align: justify;
  padding: .5rem;
}

.privacy-button{
  all: unset;
  cursor: pointer;
  padding: 0.4rem !important;
  font-size: .9rem;
  color: black;
  text-decoration: none;
  text-align: center;
}

.service-title {
  padding-top: 1rem;
  padding-bottom: 1rem;
  color: white;
}

.service-icon{
  margin-left: 1rem;
  padding-top: 0;
  border-radius: 1rem;
}

.project-individual{
  width: 80% !important;
}

.card-projects:hover, .card-service:hover {
    transition: 0.3s;
    border: 0.08rem solid var(--code-block-fun);
    background-color: #f5f5f5;
    box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
}
.card-projects{
  border: none !important;
  --bs-card-inner-border-radius: 10px!important;
  max-height: 50vh;
  overflow: hidden;
}
.arrow{
  height: 0;
  padding: 0;
  margin: 0;
}

.project-title{
  border-bottom: 1px solid rgba(43,46,52,.5);
  padding-bottom: 1rem;
  text-align: left;
}

.project-type{
  text-align: left;
  padding-left: 0;
}
.work-title{
  padding-top: 1rem;
  padding-bottom: 1rem;
  text-align: left;
}
.card-img-top{
  object-fit: cover;
  overflow: hidden;
  display: inline;
}

.card-single-project{
  border: none !important;
}

.header-links{
  justify-content: flex-end;
}

.about {
  text-align: left;
}

.work {
  text-align: right;
}

@media screen and (max-width: 1400px) {   
.para-services{
  padding: .2rem;
  font-size: 1rem;
  }
  .contact-info{
    max-width: 100%;
  }
  .box-question{
    width: 100%;
    padding: 1rem;
    flex-direction: column;
  }
  .question-section{
    min-width: 5rem;
  }
  .our-expertise{
    width: 100%;
  }
  .cities{
    flex-direction: column;
  }
  .london-div{
    max-width: 100%;
    }
}
.white-text{
  top: 0;
  margin-top: 0;
  position: relative;
}

@media screen and (max-width: 900px) {  
  .project-description{
    width: 100%;
  }
 .scroll, .scroll .stoke{
    stroke: var(--black)!important; 
    stroke-width: 2.5px!important;
    fill: var(--black)!important;
    height: 9rem;
    margin: .5rem;
  }

  .scroll:hover .stoke, .scroll:hover {
    stroke: var(--black)!important; 
    stroke-width: 2.5px!important;
    fill: var(--black)!important;
    height: 9rem;
  }

  .container-intro{
    width: 100%;
    max-width: 100%;
    min-width: 100%;
  }
  .slogan{
    text-align: center !important;
  }
  .logo{
    height: 2.5rem;
  } 
  .container{
    max-width: 100% !important;
    padding-right: 1.5rem !important;
    padding-left: 1.5rem !important;
  }
  .container-page03{
    padding: 1rem;
  }
  .para-services{
    padding: 0rem;
    padding-bottom: 1rem;
    }
  .p-service-home{
    padding: .6rem;
  }
  .white-text{
    display: none;
  }
  .input-group {
    display: flex;
    flex-direction: column;
  }
  .form-control {
      width: 100%!important;
      margin-bottom: 1rem!important;
    }
  .input-group * {
   border-radius: 5px!important;
  }
  .intro-image::after {
    content: none;
  }
  .services-box{
    flex-direction: column;
    height: auto;
    margin: auto;
    justify-content: space-evenly;
  }
  .model-canvas{
    height: 250px;
    margin-bottom: 1rem;
  }
  .services-list{
    margin-left: 0!important;
    justify-content: space-evenly;
    height: 100%;
    flex-direction: row;
    flex-wrap: wrap;
    width: auto;
  }
  .list{
    width: 100%;
  }
  .service-icon{
    margin-left: 0;
  }
  .model-questions{
    padding: 1rem;
  }
  .model-questions>*{
    border: .1rem solid #6c757d;
    border-radius: .5rem;
  }
  .aboutus-block{
    width: 100%;
    display: flex;
    flex-direction: column;
  }
  footer {
    flex-direction: column;
  }
  p {
    text-align: center;
    font-size: .9rem!important;
  }
  a{
    font-size: .9rem;
  }
  ul {
    height: auto;
    padding: 0rem;
    padding-left: 0rem !important;
  }
}

@media screen and (max-width: 600px) {   
  .container{
    max-width: 100% !important;
    padding-right: 1rem !important;
    padding-left: 1rem !important;
  }
  footer {
    flex-direction: column;
  }
  a{
    font-size: .8rem;
  }
  .question-logo{
    width: 100%!important;
    padding-bottom: 2rem!important;
  }
  .privacy-button{
    padding: 0.4rem !important;
    font-size: .8rem;
  }
}
@media screen and (max-width: 300px) {   
  a{
    font-size: .7rem;
  }
  .privacy-button{
    font-size: .7rem;
  }
}